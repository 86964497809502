import { useState, useRef, useCallback, useEffect } from 'react'

import {
  DropdownContainer,
  Input,
  DropdownList,
  ListItem,
  NoOptionsMessage
} from './Dropdown.styled'

type Option = {
  id: number
  label: string
}

type DropdownProps = {
  options: Option[]
  fetchMoreOptions?: () => void
  inputValue: string
  setInputValue: (value: string) => void
  noOptionsMessage: string | null
  placeholder?: string
  field?: {
    value: string
    onChange: (value: Option) => void
  }
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  fetchMoreOptions,
  inputValue,
  setInputValue,
  noOptionsMessage,
  placeholder,
  field
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleSelect = (option: Option) => {
    setInputValue(option.label)
    field && field.onChange(option)
    setIsOpen(false)
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollTop + clientHeight >= scrollHeight) {
      fetchMoreOptions && fetchMoreOptions()
    }
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <Input
        type="text"
        value={inputValue}
        onClick={handleToggle}
        onChange={e => {
          setInputValue(e.target.value)
          if (!isOpen) setIsOpen(true)
        }}
        placeholder={placeholder}
        data-test-id="searchbox-input"
      />
      {isOpen && options?.length ? (
        <DropdownList
          onScroll={handleScroll}
          data-testid="searchbox-suggestions-list"
        >
          {options.length > 0 ? (
            options.map(option => (
              <ListItem
                key={option.id}
                onClick={() => handleSelect(option)}
                data-test-id={`searchbox-item-${option.id}`}
              >
                {option.label}
              </ListItem>
            ))
          ) : (
            <NoOptionsMessage>{noOptionsMessage}</NoOptionsMessage>
          )}
        </DropdownList>
      ) : (
        <></>
      )}
    </DropdownContainer>
  )
}

export default Dropdown
