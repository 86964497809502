import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import StyledDatePicker from './StyledDatePicker'

interface DateTimeInputProps {
  placeholder: string
  type: 'start' | 'end'
}

const DateTimeInput: FC<DateTimeInputProps> = ({ placeholder, type }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={`${type}Date`}
      control={control}
      render={({ field }) => (
        <StyledDatePicker
          placeholder={placeholder}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          noMargin
          type={type}
          //@ts-ignore
          disabledDays={{ before: new Date() }}
          data-test-id="date-input"
        />
      )}
    />
  )
}

export default DateTimeInput
