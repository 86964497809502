import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { ServicesValuesTypes } from '../components/Filters/Services/Services.types'

export const useServiceFilter = () => {
  const [selectedServices, setSelectedServices] = useState<
    ServicesValuesTypes[]
  >([])
  const router = useRouter()

  const handleAddRemoveService = (service: ServicesValuesTypes) => {
    const updatedServices = selectedServices.includes(service)
      ? selectedServices.filter(s => s !== service)
      : [...selectedServices, service]

    setSelectedServices(updatedServices)

    const newQuery: any = { ...router.query, service: updatedServices }
    router.push(
      {
        pathname: router.pathname,
        query: newQuery
      },
      undefined,
      { shallow: true }
    )
  }

  const syncServicesWithQuery = () => {
    if (router.query.service) {
      const services = Array.isArray(router.query.service)
        ? router.query.service
        : [router.query.service]
      setSelectedServices(services as ServicesValuesTypes[])
    }
  }

  useEffect(() => {
    syncServicesWithQuery()
  }, [router.query])

  return { selectedServices, setSelectedServices, handleAddRemoveService }
}
