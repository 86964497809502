import { useTranslation } from 'next-i18next'

import { useIsMobile } from '@/utils/useMobile'

import DateTimeInput from '@/components/form/DateTime/DateTimeInput'

import { Stroker } from '../../SearchNearParking.styled'

import {
  MobileDateWrapper,
  MobileDateSeparator
} from './DateTimeControls.styled'

const DateTimeControls = () => {
  const { isMobile } = useIsMobile()
  const { t } = useTranslation('parkings')
  const startDatePlaceholder = t('searchNearParking.search.startDate')
  const endDatePlaceholder = t('searchNearParking.search.endDate')

  return isMobile ? (
    <MobileDateWrapper data-test-id="mobile-date-wrapper">
      <DateTimeInput
        type="start"
        placeholder={startDatePlaceholder}
        data-test-id="start-date-input"
      />
      <MobileDateSeparator data-test-id="mobile-date-separator">
        -
      </MobileDateSeparator>
      <DateTimeInput
        type="end"
        placeholder={endDatePlaceholder}
        data-test-id="end-date-input"
      />
    </MobileDateWrapper>
  ) : (
    <>
      <DateTimeInput
        type="start"
        placeholder={startDatePlaceholder}
        data-test-id="start-date-input"
      />
      <Stroker data-test-id="stroker" />
      <DateTimeInput
        type="end"
        placeholder={endDatePlaceholder}
        data-test-id="end-date-input"
      />
    </>
  )
}

export default DateTimeControls
