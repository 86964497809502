import { useRouter } from 'next/router'

import { LocationType } from './SearchNearParking.types'
import { ServicesValuesTypes } from './components/Filters/Services/Services.types'

export const updateQueryParams = (
  location: LocationType,
  startDate: string,
  endDate: string,
  market: string,
  radius: number | undefined
) => {
  const updatedQueryParams: any = {
    countryCode: market,
    visible: true,
    size: 30,
    sorting: 'distance;asc',
    startDate: startDate || undefined,
    endDate: endDate || undefined,
    ...(radius !== undefined && { radius })
  }

  if (location) {
    updatedQueryParams.latLon = `${location.value[1]},${location.value[0]}`
  }

  return updatedQueryParams
}

export const syncFormWithQuery = (
  query: any,
  form: any,
  setLatLon: (val: [number, number] | undefined) => void,
  setFilterOptions: (val: any[]) => void,
  setQueryParams: (val: any) => void,
  market: string,
  setSelectedServices: (services: ServicesValuesTypes[]) => void,
  setRadius: (val: number | undefined) => void
) => {
  const location: LocationType =
    query.lat && query.lon
      ? {
          label: query.locationLabel || '',
          value: [parseFloat(query.lon), parseFloat(query.lat)]
        }
      : ''

  const startDate = query.startDate || ''
  const endDate = query.endDate || ''
  const radius = query.radius ? parseInt(query.radius) : undefined

  form.setValue('location', location)
  form.setValue('startDate', startDate)
  form.setValue('endDate', endDate)

  const newFilterOptions = []

  if (location && typeof location !== 'string') {
    newFilterOptions.push({
      id: 'location',
      value: location.label
    })
    setLatLon([location.value[1], location.value[0]])
  } else {
    setLatLon(undefined)
  }

  if (startDate || endDate) {
    newFilterOptions.push({
      id: 'date',
      value: `${startDate}${startDate && endDate ? ' - ' : ''}${endDate}`
    })
  }

  let servicesFromQuery: string[] = []
  if (typeof query.service === 'string') {
    servicesFromQuery = query.service.split(',')
  } else if (Array.isArray(query.service)) {
    servicesFromQuery = query.service
  }

  const selectedServicesFromQuery = servicesFromQuery.map(
    (service: any) => service as ServicesValuesTypes
  )

  setSelectedServices(selectedServicesFromQuery)
  setFilterOptions(newFilterOptions)
  setQueryParams(
    updateQueryParams(location, startDate, endDate, market, radius)
  )
}
