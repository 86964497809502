import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import mapboxSdk from '@mapbox/mapbox-sdk/services/geocoding'
import getConfig from 'next/config'

interface UseSearchBoxParams {
  market: string
  t: (key: string, options?: any) => string
}

export const useSearchBox = ({ market, t }: UseSearchBoxParams) => {
  const {
    publicRuntimeConfig: { MAP_BOX_KEY }
  } = getConfig()
  const [inputValue, setInputValue] = useState('')
  const mapboxClient = mapboxSdk({
    accessToken: MAP_BOX_KEY
  })
  const [options, setOptions] = useState<any>([])
  const loadOptions = useCallback(
    debounce((inputValue: string) => {
      if (inputValue.length > 3) {
        mapboxClient
          .forwardGeocode({
            query: inputValue,
            autocomplete: true,
            limit: 5,
            countries: [market],
            ...(market !== 'bh' && {
              types: ['address', 'poi', 'poi.landmark', 'place']
            })
          })
          .send()
          .then(response => {
            const options = response.body.features.map((feature: any) => ({
              label: formatOptionLabel(feature),
              value: feature.center as [number, number]
            }))
            setOptions(options)
          })
          .catch(err => {
            console.error('Error fetching data:', err)
            setOptions([])
          })
      } else {
        setOptions([])
      }
    }, 300),
    [market]
  )

  const formatOptionLabel = (feature: any): string => {
    const placeName = feature.text
    const address =
      feature.address ||
      feature.context?.find((ctx: any) => ctx.id.includes('address'))?.text
    const city = feature.context?.find((ctx: any) =>
      ctx.id.includes('place')
    )?.text

    // Combine and clean up the label
    const labelParts = [address || placeName, city]
    const cleanedLabel = labelParts.filter(Boolean).join(', ')

    return cleanedLabel
  }

  useEffect(() => {
    if (inputValue.length > 3) {
      loadOptions(inputValue)
    }
  }, [inputValue, loadOptions])

  const noOptionsMessage = inputValue?.length > 3 ? t('noResults.title') : null

  return {
    setInputValue,
    noOptionsMessage,
    inputValue,
    options
  }
}
