import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { Parking } from '@/service'

interface PriceRange {
  min: number
  max: number
}

export const usePriceFilter = () => {
  const [priceRange, setPriceRange] = useState<PriceRange>({
    min: 0,
    max: 1000
  })
  const [isDragging, setIsDragging] = useState(false)
  const router = useRouter()

  const handlePriceChangeComplete = (minPrice: number, maxPrice: number) => {
    setPriceRange({ min: minPrice, max: maxPrice })
    setIsDragging(false)

    const newQuery = { ...router.query, minPrice, maxPrice }
    router.push(
      {
        pathname: router.pathname,
        query: newQuery
      },
      undefined,
      { shallow: true }
    )
  }

  const syncPriceWithQuery = () => {
    const queryMinPrice = router.query.minPrice
      ? Number(router.query.minPrice)
      : 0
    const queryMaxPrice = router.query.maxPrice
      ? Number(router.query.maxPrice)
      : 1000
    setPriceRange({
      min: queryMinPrice,
      max: queryMaxPrice
    })
  }

  useEffect(() => {
    syncPriceWithQuery()
  }, [router.query])

  const filterParkingsByPrice = (parkings: Parking[]) => {
    if (isDragging) return parkings

    return parkings.filter(parking => {
      const price =
        parking.price?.pricePerHour?.amount ||
        parking.price?.pricePer24Hours?.amount
      return price >= priceRange.min && price <= priceRange.max
    })
  }

  return { priceRange, handlePriceChangeComplete, filterParkingsByPrice }
}
