import { useQuery } from '@tanstack/react-query'

import { ApiPaths } from '@/utils/apiPaths'
import { fetchParkings } from '@/utils/parkingsService'

import { AllParkingsParams } from '../parkings'

export const useFetchParkings = ({ params }: { params: AllParkingsParams }) => {
  const { data, isLoading } = useQuery({
    queryKey: [ApiPaths.API_V1_PARKINGS, params],
    queryFn: () =>
      fetchParkings({
        params
      })
  })
  return {
    parkings: data?.items,
    isLoading
  }
}
