import { useState } from 'react'
import { useRouter } from 'next/router'
import { UseFormReturn } from 'react-hook-form'

import { LocationType, FormValues } from '../SearchNearParking.types'

export const useLocationFilter = (form: UseFormReturn<FormValues>) => {
  const [latLon, setLatLon] = useState<[number, number] | undefined>(undefined)
  const router = useRouter()

  const setLocation = (location: LocationType | undefined) => {
    if (location) {
      setLatLon([location.value[1], location.value[0]])
      form.setValue('location', location)
    } else {
      setLatLon(undefined)
      form.setValue('location', '')
    }
  }

  const syncLocationWithQuery = () => {
    if (router.query.lat && router.query.lon) {
      setLatLon([
        parseFloat(router.query.lat as string),
        parseFloat(router.query.lon as string)
      ])
    }
    if (router.query.locationLabel) {
      form.setValue('location', {
        label: router.query.locationLabel as string,
        value: [
          parseFloat(router.query.lon as string),
          parseFloat(router.query.lat as string)
        ]
      } as LocationType)
    }
  }

  return { latLon, setLocation, syncLocationWithQuery, setLatLon }
}
