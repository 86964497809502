import { useEffect, useState } from 'react'

import { handleScroll } from '../utils'

export const useScrollEffect = () => {
  const [isFixed, setIsFixed] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScrollEvent = () => handleScroll(setIsFixed, setIsVisible)
    window.addEventListener('scroll', handleScrollEvent)
    return () => window.removeEventListener('scroll', handleScrollEvent)
  }, [])

  return { isFixed, isVisible }
}
