import styled from '@emotion/styled'

import { mediaColumns } from '@/styles/grid'
import { mediaUp } from '@/styles/media'

export const Container = styled.section`
  ${mediaColumns({ xs: [12, 0] })};
`

export const LoadMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  ${mediaUp('lg')} {
    margin-top: 40px;
  }
`

export const ParkingWrap = styled.div<{ col?: number }>`
  margin-bottom: 18px;
  width: 100%;

  ${mediaUp('md')} {
    margin: 0 6px 12px;
    width: calc(33.33% - 12px);
  }

  ${mediaUp('lg')} {
    margin: 0 10px 20px;
    width: calc(33.33% - 20px);
  }

  ${mediaUp('xl')} {
    margin: 0 10px 40px;
  }
`

export const ResultsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 22px -4px -4px;

  ${mediaUp('md')} {
    margin: 22px -6px -6px;
  }

  ${mediaUp('lg')} {
    margin: 80px -10px -10px;
  }
`
