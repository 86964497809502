import styled from '@emotion/styled'

import { rgba } from 'polished'

import { columnWidth, mediaColumns, Grid } from '@/styles/grid'
import { mediaDown, mediaUp } from '@/styles/media'
import { sliderButtons } from '@/styles/slider'
import ChevronLeft from '@/components/icons/ChevronLeft'
import ChevronRight from '@/components/icons/ChevronRight'
import { transitions, colors } from '@/styles/theme'

export const GridWrapper = styled(Grid)`
  position: relative;
`

export const Wrapper = styled.section<{ resetMarginBottom?: boolean }>`
  margin: ${props => (props.resetMarginBottom ? '40px 0 0;' : '40px 0 60px;')};

  ${mediaUp('lg')} {
    margin: ${props =>
      props.resetMarginBottom ? '80px 0 0;' : '80px 0 120px;'};
  }
`

export const Container = styled.div<{ narrow?: boolean }>`
  overflow: hidden;
  width: 100%;
  margin-bottom: -25px;
  margin-top: -25px;

  .swiper {
    ${props =>
      props.narrow
        ? mediaColumns({ xs: [12, 0], lg: [10, 2] })
        : mediaColumns({ xs: [12, 0] })};
    width: 100%;
    overflow: visible;
    padding: 25px 0 25px;
  }

  .swiper-slide {
    &:last-child {
      margin-inline-end: 0;
    }

    ${mediaDown('md')} {
      ${props =>
        props.narrow ? columnWidth(6, 'width: ') : columnWidth(10, 'width: ')};
      max-width: ${props => (props.narrow ? '136px' : '288px')};
    }

    ${mediaUp('md')} {
      ${props =>
        props.narrow ? columnWidth(3, 'width: ') : columnWidth(5, 'width: ')};
      max-width: ${props => (props.narrow ? '201px' : '288px')};
    }

    ${mediaUp('lg')} {
      ${props => props.narrow && columnWidth(4, 'width: ')};
      max-width: ${props => (props.narrow ? '400px' : '510px')};
    }

    ${mediaUp(1920)} {
      ${columnWidth(4, 'width: ')};
    }

    ${mediaUp(2230)} {
      ${columnWidth(3, 'width: ')};
    }
  }
`

export const Buttons = styled.div`
  ${mediaColumns({ xs: [12, 0] })};
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaUp('md')} {
    justify-content: flex-end;
  }
`

export const ButtonWrapper = styled.button`
  ${sliderButtons};
  position: relative;
  margin-bottom: 40px;

  ${mediaUp('md')} {
    transform: translate(0, -100%);
    margin-bottom: 16px;
  }

  ${mediaUp('lg')} {
    margin-bottom: 32px;
  }
`

export const ButtonNext = styled(ChevronRight)``

export const ButtonPrev = styled(ChevronLeft)``

export const TitleWrapper = styled.div`
  ${mediaColumns({ xs: [12, 0], md: [10, 1] })};

  ${mediaDown('md')} {
    margin-bottom: 18px;
  }
`

export const ButtonsHover = styled.div`
  ${mediaColumns({ xs: [12, 0] })};
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10000;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  transform: translate(0, -50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity ${transitions.base};

  ${GridWrapper}:hover & {
    opacity: 1;
  }

  button.discover-button-next {
    margin-inline-end: 39px;

    ${mediaUp('lg')} {
      margin-inline-end: 68px;
    }
  }

  button.discover-button-prev {
    margin-inline-start: 39px;

    ${mediaUp('lg')} {
      margin-inline-start: 68px;
    }
  }

  ${mediaDown('md')} {
    display: none;
  }
`

export const ButtonHoverWrapper = styled.button`
  ${sliderButtons};
  position: relative;
  pointer-events: all;
  opacity: 1;
  transition: opacity ${transitions.base}, background-color ${transitions.base};
  color: ${colors.white};
  background-color: ${rgba(colors.paleGrey, 0.5)};

  &.swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    background-color: ${rgba(colors.paleGrey, 0.65)};
  }
`
