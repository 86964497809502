import styled from '@emotion/styled'

export const MobileDateWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 1px;
  color: #6c7075;
  justify-content: center;
`
export const MobileDateSeparator = styled.div`
  width: 16px;
  text-align: center;
  font-size: 13px;
  margin-right: 8px;
`
