import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { format, isValid, parse } from 'date-fns'
import FocusTrap from 'focus-trap-react'
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker'
import styled from '@emotion/styled'

import 'react-day-picker/dist/style.css'
import { enUS, pl } from 'date-fns/locale'

import { reverseDate } from '@/utils/common'

import { useMarket } from '@/utils/multi-markets/context'

import { DayPickerStyles } from '../DatePicker/DayPickerStyles'

interface Props {
  value: string
  placeholder?: string
  noMargin?: boolean
  disabledDays?: Date[]
  onChange(value: string): void
  onBlur?(): void
  dataTestId?: string
  isStyled?: boolean
  type: 'end' | 'start'
}

const localeMap: { [key: string]: Locale } = {
  en: enUS,
  pl: pl
}

export default function StyledDatePicker({
  value,
  placeholder = '',
  noMargin = false,
  onChange,
  onBlur,
  disabledDays,
  dataTestId,
  type
}: Props) {
  const [selected, setSelected] = useState<Date | undefined>(
    value ? parse(value, 'yyyy-MM-dd', new Date()) : undefined
  )
  const { locale } = useMarket()
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  function formatDate(day: Date): string {
    return format(day, 'dd-MM-yyyy')
  }
  let formattedValue = ''
  if (value && Date.parse(value)) {
    const date = reverseDate(value)
    formattedValue = formatDate(new Date(date.year, date.month, date.day))
  }
  const closePopper = useCallback(() => {
    setIsPopperOpen(false)
    buttonRef.current?.focus()
    if (onBlur) onBlur()
  }, [onBlur])

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    const date = parse(e.target.value, 'yyyy-MM-dd', new Date())
    if (isValid(date)) {
      setSelected(date)
      onChange(format(date, 'yyyy-MM-dd'))
    } else {
      setSelected(undefined)
    }
  }

  const handleButtonClick = () => {
    setIsPopperOpen(!isPopperOpen)
  }

  const handleDaySelect: SelectSingleEventHandler = date => {
    setSelected(date)
    if (date) {
      onChange(format(date, 'yyyy-MM-dd'))
    }
    closePopper()
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node)
      ) {
        closePopper()
      }
    }
    if (isPopperOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [closePopper, isPopperOpen])

  return (
    <DayPickerStyles noMargin={noMargin} data-test-id={dataTestId}>
      <div ref={popperRef} className={noMargin ? 'no-margin' : ''}>
        <InputContainer>
          <StyledInput
            value={formattedValue}
            onChange={handleInputChange}
            placeholder={placeholder}
            readOnly
            onClick={handleButtonClick}
            data-test-id={`day-picker-input-${type}`}
          />
        </InputContainer>

        {isPopperOpen && (
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
              fallbackFocus: buttonRef.current || undefined
            }}
          >
            <StyledDropdown>
              <DayPicker
                initialFocus={isPopperOpen}
                mode="single"
                defaultMonth={selected}
                selected={selected}
                locale={localeMap[locale] || enUS}
                onSelect={handleDaySelect}
                disabled={disabledDays}
                data-test-id="day-picker-dropdown"
              />
            </StyledDropdown>
          </FocusTrap>
        )}
      </div>
    </DayPickerStyles>
  )
}

const StyledDropdown = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: 1024px) {
    top: 44px;
  }
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  color: #6c7075;
  margin-left: -4px;
  @media (min-width: 769px) {
    width: 90px;
    color: #0b4684;
    margin-left: unset;
  }
  @media (min-width: 1024px) {
    width: 104px;
  }
`

const StyledInput = styled.input`
  width: 70px;
  font-family: 'Nunito';
  font-size: 12px;
  color: #6c7075;
  text-align: center;

  font-weight: 600;
  &::placeholder {
    color: #6c7075;
  }

  @media (min-width: 769px) {
    font-size: 16px;
    width: 90px;
    color: #0b4684;
    &::placeholder {
      color: #0b4684;
    }
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    width: 104px;
  }
`
