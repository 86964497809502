import { useTranslation } from 'next-i18next'

import searchIcon from '@/assets/search.svg'

import searchMobileIcon from '@/assets/searchMobile.svg'

import { useIsMobile } from '@/utils/useMobile'

import SearchBox from '../SearchBox'

import { Stroker } from '../../SearchNearParking.styled'
import DateTimeControls from '../DateTimeControls/DateTimeControls'

import {
  OptionsWrapper,
  MobileSearchBoxWrapper,
  SearchButtonWrapper,
  MobileSearchWrapper
} from './Options.styled'

const Options = ({
  isFixed,
  isVisible,
  market
}: {
  isFixed?: boolean
  isVisible?: boolean
  market: string
}) => {
  const { isMobile } = useIsMobile()
  const { t } = useTranslation('parkings')
  const placeholder = t('searchNearParking.search.placeholder')
  const translatedSearchBtn = t('searchNearParking.search.button')

  return isMobile ? (
    <MobileSearchBoxWrapper
      isFixed={isFixed}
      isVisible={isVisible}
      data-test-id="mobile-search-box-wrapper"
    >
      <OptionsWrapper data-test-id="options-wrapper">
        <MobileSearchWrapper data-test-id="mobile-search-wrapper">
          <SearchBox market={market} placeholder={placeholder} />
          <DateTimeControls />
        </MobileSearchWrapper>
        <SearchButtonWrapper
          type="submit"
          data-test-id="search-button-wrapper"
          data-testid="search-box-button"
        >
          <img src={searchMobileIcon} alt="searchMobileIcon" />
          <span>{translatedSearchBtn}</span>
        </SearchButtonWrapper>
      </OptionsWrapper>
    </MobileSearchBoxWrapper>
  ) : (
    <OptionsWrapper data-test-id="options-wrapper">
      <SearchBox market={market} placeholder={placeholder} />
      <Stroker />
      <DateTimeControls />
      <SearchButtonWrapper type="submit" data-test-id="search-button-wrapper">
        <img src={searchIcon} alt="searchIcon" />
        <span>{translatedSearchBtn}</span>
      </SearchButtonWrapper>
    </OptionsWrapper>
  )
}

export default Options
