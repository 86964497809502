import { css } from '@emotion/react'

import { rgba } from 'polished'

import { mediaUp } from '@/styles/media'
import { focusVisible } from '@/styles/mixins'
import { colors, transitions } from '@/styles/theme'

export const sliderButtons = css`
  ${focusVisible};
  background-color: ${colors.paleGrey};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  color: ${colors.darkSkyBlue};
  transition: background-color ${transitions.base};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;

  ${mediaUp('lg')} {
    width: 48px;
    height: 48px;
    margin: 0;
    margin-inline-start: 20px;
  }

  svg {
    stroke: currentColor;
    stroke-width: 4px;
    width: 75%;
    height: 75%;

    ${mediaUp('lg')} {
      stroke-width: 4px;
    }
  }

  &:hover {
    background-color: ${colors.paleHover};
  }

  &[disabled] {
    background-color: ${rgba(colors.cloudyBlue, 0.12)};
    color: ${rgba(colors.blueyGrey, 0.33)};
  }
`
