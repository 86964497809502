import styled from '@emotion/styled'

export const DropdownContainer = styled.div`
  position: relative;
  width: 214px;

  @media (min-width: 769px) {
    width: 320px;
  }

  @media (min-width: 1024px) {
    width: unset;
  }
`

export const Input = styled.input`
  width: 100%;
  font-family: Nunito;
  font-size: 12px;
  color: #0b4684;
  font-weight: 600;
  box-sizing: border-box;
  @media (min-width: 769px) {
    width: 320px;
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    width: 380px;
    font-size: 18px;
    padding: 8px;
  }

  &::placeholder {
    color: #0b4684;
    font-weight: 600;
    font-size: 12px;
    @media (min-width: 769px) {
      font-size: 16px;
    }
    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }
`

export const DropdownList = styled.div`
  all: unset;
  position: absolute;
  top: 52px;
  left: -32px;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  z-index: 1000;
  box-shadow: 0px 4px 24px 0px rgba(9, 55, 105, 0.08);
  border-radius: 16px;
  width: 330px;
  @media (min-width: 769px) {
    width: 460px;
    border-radius: 24px;
    max-height: 200px;
    top: 54px;
    left: -32px;
  }
`

export const ListItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  background-color: white;
  border-radius: 16px;
  font-size: 12px;
  color: #0b4684;
  font-family: Nunito;

  &:hover {
    background: #f2f6fd;
  }

  @media (min-width: 769px) {
    padding: 8px 16px;
    border-radius: 24px;
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    padding: 10px 20px;
    border-radius: 24px;
    font-size: 18px;
  }
`

export const NoOptionsMessage = styled.div`
  padding: 8px;
  color: #999;
  text-align: center;
  font-size: 12px;
  font-family: Nunito;

  @media (min-width: 769px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`
