import { FC, useEffect, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useRouter } from 'next/router'

import { useTranslation } from 'next-i18next'

import Dropdown from '@/components/Dropdown/Dropdown'

import { SearchBoxProps } from '../SearchNearParking.types'

import { useSearchBox } from './useSearchBox'

const SearchBox: FC<SearchBoxProps> = ({ market, placeholder }) => {
  const { control } = useFormContext()
  const { field } = useController({
    name: 'location',
    control
  })
  const { t } = useTranslation('parkings')
  const router = useRouter()
  const { setInputValue, noOptionsMessage, inputValue, options } = useSearchBox(
    { market, t }
  )
  const [isSetted, setIsSetted] = useState(false)

  useEffect(() => {
    if (!inputValue && field?.value?.label && !isSetted) {
      setInputValue(field.value.label)
      setIsSetted(true)
    }
  }, [field, inputValue, isSetted, setInputValue])

  useEffect(() => {
    const locationLabel = router.query.locationLabel as string | undefined
    if (locationLabel !== inputValue) {
      setInputValue(locationLabel || '')
    }
  }, [router.query.locationLabel])

  return (
    <Dropdown
      options={options}
      noOptionsMessage={noOptionsMessage}
      inputValue={inputValue}
      placeholder={placeholder}
      setInputValue={setInputValue}
      field={field}
      data-test-id="dropdown"
    />
  )
}

export default SearchBox
