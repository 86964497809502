import { useState } from 'react'

import { FilterOptionTypes } from '../components/Filters/Filters.types'

export const useOverlayToggle = () => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  const handleOverlayToggle = () => setIsOverlayVisible(!isOverlayVisible)

  return { isOverlayVisible, handleOverlayToggle, setIsOverlayVisible }
}
