import { css } from '@emotion/react'
import { useTranslation } from 'next-i18next'

import { useMarket } from '@/utils/multi-markets/context'

import { Price } from '@/service'

import { mediaUp } from '@/styles/media'
import { colors } from '@/styles/theme'
import { Text2 } from '@/styles/typography'

interface Props {
  price: Price
  isAbsolute?: boolean
  noMargin?: boolean
  background?: string
}

function PriceTag(props: Props) {
  const {
    price,
    isAbsolute = true,
    noMargin = false,
    background = colors.white
  } = props
  const { t } = useTranslation('common')
  const { locale } = useMarket()

  const formattedPrice =
    locale === 'ar'
      ? new Intl.NumberFormat('ar-EG').format(price.amount)
      : price.amount

  const currencyText = t(`currency${price.currency}` as any)

  return (
    <Text2 css={styles.priceTag(isAbsolute, noMargin, background)}>
      {t('priceFrom')}
      <strong>
        {' '}
        {formattedPrice} {currencyText}
      </strong>
    </Text2>
  )
}

const styles = {
  priceTag: (isAbsolute: boolean, noMargin: boolean, background: string) => css`
    ${isAbsolute &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `}
    margin: ${noMargin ? '0' : '14px'};
    padding: 4px 8px;
    border-radius: 12px;
    background-color: ${background};
    color: ${colors.twilightBlue};

    ${mediaUp('lg')} {
      margin: ${noMargin ? '0' : '30px'};
      padding: 5px 12px;
      border-radius: 17px;
    }

    html[lang^='ar'] & {
      left: unset;
      right: 0;
    }
  `
}

export default PriceTag
