import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'

import { fadeIn } from '../../SearchNearParking.styled'

export const OptionsWrapper = styled.div`
  display: flex;
  max-width: 1024px;
  margin: 0 auto;
  padding: 8px 8px 8px 36px;
  align-items: center;
  gap: 4px;
  border-radius: 36px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(9, 55, 105, 0.08);
  width: 340px;
  ${mediaUp('md')} {
    max-width: 1024px;
    width: unset;
    gap: 12px;
  }
  ${mediaUp('lg')} {
    gap: 36px;
  }
`

export const MobileSearchBoxWrapper = styled.div<{
  isFixed?: boolean
  isVisible?: boolean
}>`
  background: ${({ isFixed }) => (isFixed ? '#f2f6fd' : 'transparent')};
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'relative')};
  top: ${({ isFixed }) => (isFixed ? '42px' : 'unset')};
  z-index: 1000;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : 'none')} 0.3s
    ease-in-out;
  width: ${({ isFixed }) => (isFixed ? '100vw' : 'unset')};
  padding: ${({ isFixed }) => (isFixed ? '8px' : 'unset')};
  box-sizing: border-box;
  opacity: ${({ isVisible, isFixed }) => (isFixed ? (isVisible ? 1 : 0) : 1)};
  transition: opacity 0.3s ease-in-out;
  padding-bottom: ${({ isFixed }) => (isFixed ? '4px' : '24px')};
`

export const SearchButtonWrapper = styled.button`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #fff;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  border-radius: 40px;
  background: #3586e6;

  ${mediaUp('md')} {
    padding: 15px 36px;
    gap: 12px;
    font-size: 18px;
    border-radius: 28px;
  }
`
export const MobileSearchWrapper = styled.div`
  display: grid;
`
